<template>
    <div class="layout">
        <div class="layout-header"></div>
        <div class="layout-banner"></div>
        <div class="container1500 mh500">
            <div class="layout-main-top"></div>
            <div class="layout-main-middle">
                <div class="title">
                    <p></p>
                    <p><span>联系我们</span></p>
                    <p></p>
                </div>
                <p class="title-en">Contact Us</p>
                <p class="ml485">深圳市创环环保科技有限公司</p>
                <p class="ml485">
                    地址：广东省深圳市龙岗龙城街道清林西路留学生创业园一园南区205室
                </p>
                <p class="ml485">邮编：518000</p>
                <p class="ml485">电话：0755-26519737 | 17727811780</p>
                <!-- <p class="ml485">邮箱：EcoVista@163.com</p> -->
            </div>
        </div>
        <NavFooter />
    </div>
</template>
<script>
import NavFooter from "../../components/NavFooter.vue";

export default {
    name: "ContactUs",
    components: {
        NavFooter,
    },
    mounted() {},
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/mixin.scss";
.layout {
    position: relative;
    width: 100%;
    z-index: 0;
    .layout-header {
        height: 100px;
    }
    .layout-banner {
        width: 100%;
        height: 350px;
        background: url("../../assets/img/banner6.jpg") no-repeat center;
        background-size: cover;
    }
    .mh500 {
        min-height: 500px;
        .layout-main-top {
            height: 40px;
            border-bottom: 1px solid #bcbcbc50;
        }
        .layout-main-middle {
            padding: 40px 0;
            min-height: 460px;
            box-sizing: border-box;
            .title {
                width: 100%;
                height: 39px;
                display: flex;
                justify-content: center;
                p {
                    width: 20px;
                    height: 19px;
                    font-size: 26px;
                    border-bottom: 1px solid #0e2686;
                }
                :nth-child(2) {
                    width: 426px;
                    height: 39px;
                    line-height: 39px;
                    text-align: center;
                    border-bottom: none;
                }
            }
            .title-en {
                width: 100%;
                height: 23.5px;
                line-height: 23.5px;
                font-size: 15px;
                color: #0e2686;
                text-align: center;
                margin-bottom: 20px;
            }
            h2 {
                margin: 10px 0;
            }
            p {
                height: 50px;
                line-height: 50px;
                font-size: 18px;
            }
            .ml485 {
                margin-left: 485px;
            }
        }
    }
}
</style>